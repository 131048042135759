import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import loadable from '@loadable/component'

import withArtist from '../../components/Artist'
import { useMedia } from '../../hooks/useMedia'
import { useVideo } from '../../hooks/useVideo'

const IsotopeGrid = loadable(() => import('../../components/shared/IsotopeGrid'))

const FictionArtistDetailPage = ({ artist, acf, getReferenceIds, makeFilter, makeReferences, vitaReference, vitaTableReference }) => {
  const references = acf.feature_references || []
  const referenceIds = getReferenceIds(references)
  const media = useMedia(referenceIds)
  const videos = useVideo(referenceIds)
  const fiction_filters = makeFilter(references, 'fiction')
  const fiction_references = makeReferences(references, media, videos, artist, 'fiction')

  return (
    <IsotopeGrid type='projects' items={fiction_references}>
      <div className='section__content'>
        { acf.vita && (
          <div className="section__link">
            <a onClick={() => vitaReference.current.scrollIntoView()}>Check out filmography</a>
          </div>
         )
        }
        { artist.categories.includes(952) && (
          <div className="section__link">
            <Link to={`/artist/${artist.slug}/commercial`}>Check out commercial references</Link>
          </div>
          )
        }
        <div className='tabs'>
          <div className='tabs__body'>
            <div id='projects' className='tab is-active'>
              <div className="filter">
              <ul>
                {fiction_filters.map(({ slug, title }, i) => (
                  <li key={i} className={(i == 0) ? 'current' : ''}>
                    <a href={slug} data-category={slug}>{ title }</a>
                  </li>
                ))}
              </ul>
              </div>
              <div
                className='grid grid--controls-overlap'
                data-type='projects'
              >
                <div className='grid__inner'></div>

                <div className='grid__controls'>
                  <a href='#' className='btn load-more'>
                    Load <br />
                    More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IsotopeGrid>
  )
}

export default withArtist(FictionArtistDetailPage, 'fiction')

export const pageQuery = graphql`
  query FeatureArtistPostByID($id: String!) {
    wordpressWpArtist(id: { eq: $id }) {
      slug
      acf {
        seo_meta_title
        seo_meta_description
        seo_index
        seo_follow
        feature_references {
          section
          references {
            wordpress_id
          }
        }
        first_name
        second_name
        cv
        face {
          source_url
        }
        berlin_based
        other_location
        contact_information
        software
        seo_artist_image_alt_text
        seo_artist_page_meta_description
        seo_artist_page_meta_title
        vita
        vita_table {
          year
          event
        }
      }
      artist_category
      artist_category_name
    }
  }
`
